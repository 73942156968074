<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"/>
      <verify ref="modalVerify" @showToast="showToast" @mostrarDocumento="mostrarDocumento">
        <template v-slot:header><span></span></template>
      </verify>
      <div class="card border-warning">
        <div class="card-header">
          <strong class="text-primary">
            Registro de Factura <span class="text-success">Cliente Externo</span>
          </strong>
        </div>
        <div class="card-body p-1">
          <form ref="formCreate" novalidate>
            <div class="row mb-1">
              <div class="col-3">
                <label class="form-label" for="factura_nit"><strong>Nit Cliente</strong></label>
                <input id="factura_nit" v-model="factura.factura_nit" class="form-control"
                       type="text" @change="buscarnit();" required="required"/>
                <div class="invalid-tooltip">
                  El NIT es requerido
                </div>
              </div>
              <div class="col-5">
                <label class="form-label" for="factura_nombre"><strong>Nombre cliente</strong>
                  <template v-if="factura.factura_nombre!=''">
                    <a href="javascript:return;" class="text-danger underline" @click="modalCliente=true"> [
                      <font-awesome-icon :icon="['fa', 'pencil']"/> Modificar datos del cliente ]</a>
                  </template>
                </label>
                <input id="factura_nombre" v-model="factura.factura_nombre" readonly
                       @change="factura.factura_nombre=factura.factura_nombre.toUpperCase()"
                       class="form-control" type="text" required="required"/>
                <div class="invalid-tooltip">
                  El nombre del cliente es requerido
                </div>
              </div>
              <div class="col-2">
                <label class="form-label" for="fechapago">Fecha</label>
                <input id="fechapago" class="form-control" readonly type="text" :value="factura_fecha">
              </div>
              <div class="col-2">
                <label class="form-label" for="horapago">Hora</label>
                <input id="horapago" class="form-control" readonly type="text" :value="factura_hora">
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-1 mb-3">Si <strong>NO</strong> tiene NIT usar:
                <strong class="ml-3">99001</strong> <em>(Extranjero no inscrito)</em>
                <strong class="ml-3 text-primary">99002</strong> <em class="text-primary">(Control Tributario)</em>
                <strong class="ml-3">99003</strong> <em>(Ventas Menores)</em>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-3">
                <label class="form-label" for="estudiante_ci">CI/Pasaporte del Estudiante</label>
                <input id="estudiante_ci" v-model="factura.estudiante_ci" class="form-control"
                       type="text" @change="buscarnit()"
                       @focus="(factura.estudiante_ci==''?factura.estudiante_ci=factura.factura_nit:factura.estudiante_ci);(factura.estudiante_nombre==''?factura.estudiante_nombre=factura.factura_nombre:factura.estudiante_nombre)"/>
              </div>
              <div class="col-5">
                <label class="form-label" for="estudiante_nombre">Nombre del Estudiante:</label>
                <input id="estudiante_nombre" v-model="factura.estudiante_nombre"
                       @change="factura.estudiante_nombre=factura.estudiante_nombre.toUpperCase()"
                       @focus=""
                       class="form-control" type="text"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <label class="form-label" for="gestion">Gestión:</label>
                <select class="form-control" id="gestion"
                        v-model="factura.gestion" required="required">
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>
              </div>
              <div class="col-2">
                <CSelect
                    :options="moneda"
                    :plain="true"
                    :value.sync="factura.moneda"
                    label="Moneda:"
                    readonly="readonly"
                >
                </CSelect>
              </div>
              <div class="col-2">
                <label class="form-label" for="tipocambio">Tipo de cambio:</label>
                <input id="tipocambio" v-model="factura.tipo_cambio" class="form-control" readonly type="text">
              </div>
              <div class="col-3">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id"
                    label="Tipo de Cobro"
                    required="required"
                    @change="actualizarpagos"
                >
                </CSelect>
              </div>
              <div class="col-3" v-if="factura.tipo_cobro_id!=tipoEfectivo">
                <label class="form-label" for="tipocambio">Deposito:</label>
                <input id="deposito" v-model="factura.deposito" class="form-control"
                       type="text" @change="actualizarDepositos">
              </div>
            </div>
            <hr class=" mt-1 mb-1"/>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">#</div>
                  <div class="col-11 pl-0 pr-1 text-center">
                    <strong>A cobrar</strong>
                  </div>
                </div>
              </div>
              <div class="col-2 text-center">
                <strong>Monto</strong>
                <font-awesome-icon icon="fa-solid fa-circle-question"
                                   v-c-tooltip="'Si los montos de las <u>cuotas</u> (<u>1,2,3,4,5</u>) no son correctos debe actualizar el <b>contrato</b> del estudiante'"/>
              </div>
              <div class="col-1 pl-0 pr-0 text-center d-none">
                T. Cobro
              </div>
              <div class="col-2 pl-0 pr-0 text-center d-none">
                Depósito
              </div>
              <div class="col-3 text-center">
                Observaciones
              </div>
              <div class="col-1 pl-0">
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">1</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_1" @input="actualizaMonto(1)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input id="monto_1" v-model="factura.monto_1" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_1 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_1"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_1" v-model="factura.deposito_1" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_1 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input id="observacion_1" v-model="factura.observacion_1"
                       class="form-control" maxlength="350"
                       type="text">
              </div>
              <div class="col-1 pl-0">
                <button :class="factura.monto_1 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_1 < 1" @click="limpiar(1)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">2</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_2" @input="actualizaMonto(2)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_2" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_2 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_2"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_2" v-model="factura.deposito_2" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_2 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input v-model="factura.observacion_2" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_2 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_2 < 1" @click="limpiar(2)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">3</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_3" @input="actualizaMonto(3)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_3" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_3 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_3"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_3" v-model="factura.deposito_3" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_3 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input v-model="factura.observacion_3" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_3 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_3 < 1" @click="limpiar(3)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">4</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_4" @input="actualizaMonto(4)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_4" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_4 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_4"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_4" v-model="factura.deposito_4" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_4 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input v-model="factura.observacion_4" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_4 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_4 < 1" @click="limpiar(4)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-1 text-center">5</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_5" @input="actualizaMonto(5)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_5" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_5 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_5"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_5" v-model="factura.deposito_5" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_5 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input v-model="factura.observacion_5" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_5 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_5 < 1" @click="limpiar(5)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6">
                <div class="row">
                  <div class="col-1 text-center">6</div>
                  <div class="col-11 pl-0 pr-1">
                    <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                              v-model="factura.concepto_id_6" @input="actualizaMonto(6)"/>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_6" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_6 > 0 ? 'form-control is-valid text-center' : 'form-control is-invalid text-center']">
              </div>
              <div class="col-1 pl-0 pr-1 d-none">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_6"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1 d-none">
                <template>
                  <input id="deposito_6" v-model="factura.deposito_6" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_6 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-3 pl-0 pr-1">
                <input v-model="factura.observacion_6" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_6 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_6 < 1" @click="limpiar(6)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-6">
                <div class="row">
                  <div class="col-1 text-center"></div>
                  <div class="col-11 pl-0 pr-1 text-right text-end">
                    <strong>MONTO TOTAL</strong>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-0 pr-1 text-center">
                <strong>{{ montoTotal }}</strong>
              </div>
              <div class="col-1 pl-0 pr-1 d-none"></div>
              <div class="col-2 pl-0 pr-1 d-none"></div>
              <div class="col-3 pl-0 pr-1"></div>
              <div class="col-1 pl-0 pr-1"></div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <CButton class="mr-2" color="primary" @click="confirmarGrabar()">Generar Factura (Imprimir)</CButton>
          <CButton color="danger" @click="goBack">Ir al listado de facturas</CButton>
        </div>
      </div>
    </div>
    <CModal
        :show.sync="modalCliente"
        :no-close-on-backdrop="true"
        :centered="true"
        :closeOnBackdrop="false"
        title="Crear cliente"
        size="lg"
        color="dark"
    >
      <template #header>
        Registrar cliente
      </template>
      <form ref="frmNuevoCliente" novalidate @submit.prevent="GrabarCliente">
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="codigoTipoDocumentoIdentidad">Tipo Documento
            Identidad</label>
          <div class="col-sm-8">
            <select id="codigoTipoDocumentoIdentidad"
                    v-model="cliente.codigoTipoDocumentoIdentidad" class="form-control form-select"
                    required="required" @change="actualizaMonto(6)">
              <option v-for="option in TipoDocumentoIdentidad" :value="option.value">
                {{ option.label }}
              </option>
            </select>
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="numeroDocumento">Número Documento de Identidad</label>
          <div class="col-sm-5">
            <input id="numeroDocumento" v-model="cliente.numeroDocumento" class="form-control"
                   max="9999999999" min="1"
                   required="required"
                   step="1" type="number">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
          <div class="col-sm-3">
            <img src="/img/carnet.jpg" class="img-responsive" style="width: 100%"/>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="complemento">Complemento</label>
          <div class="col-sm-5">
            <input id="complemento" v-model="cliente.complemento" class="form-control"
                   maxlength="5" type="text" placeholder="Número para diferenciar duplicados">
            <small><em><strong>No escribir: </strong>La Paz, Oruro, Cochabamba, etc</em></small>
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
          <div class="col-sm-3">
            <img src="/img/complemento.jpg" class="img-responsive" style="width: 100%"/>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="nombreRazonSocial">Nombre / Razon Social</label>
          <div class="col-sm-8">
            <input id="nombreRazonSocial" v-model="cliente.nombreRazonSocial"
                   class="form-control" maxlength="350"
                   required="required" type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="email">Email</label>
          <div class="col-sm-8">
            <input id="email" v-model="cliente.email"
                   class="form-control" maxlength="350"
                   required="required" type="email">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="telefono">Celular</label>
          <div class="col-sm-8">
            <input id="telefono" v-model="cliente.telefono"
                   class="form-control" max="99999999" min="10000000"
                   required="required" type="number">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-success" type="submit">Grabar datos del Cliente</button>
            <button aria-label="Close" class="btn btn-outline-dark ml-3 ms-3" data-bs-dismiss="modal"
                    type="button" @click="cancelarGrabarCliente">Regresar a la pantalla anterior
            </button>
          </div>
        </div>
      </form>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm.vue";
import verify from "@/views/facturaenlinea/ModalVerify.vue";


var sourceLst;
var moment = require('moment');
var modalPdf;

export default {
  name: 'EditUser',
  components: {
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    Table,
    verify,
  },
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      modalCliente:false,
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '',
      },
      facultades: [], carreras: [],
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      factura_fecha: moment(new Date()).format("YYYY-MM-DD"),
      factura_hora: moment(new Date()).format("HH:mm"),

      multa: 2,tipoEfectivo: 0,
      cuota1: {fecha: '', diasmulta: '', multa: ''},
      cuota2: {fecha: '', diasmulta: '', multa: ''},
      cuota3: {fecha: '', diasmulta: '', multa: ''},
      cuota4: {fecha: '', diasmulta: '', multa: ''},
      cuota5: {fecha: '', diasmulta: '', multa: ''},

      factura: {
        factura_nit: '',
        factura_nombre: '',
        estudiante_ci: '',
        estudiante_nombre: '',
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        moneda_id: 0,
        tipo_cambio: 0,
        semestre: '',
        tipo_cobro_id: 0,
        sede_id: 0,
        deposito: '',
        concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1:0, deposito_1:'',
        concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2:0, deposito_2:'',
        concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3:0, deposito_3:'',
        concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4:0, deposito_4:'',
        concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5:0, deposito_5:'',
        concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6:0, deposito_6:'',
      },
      conceptoPago: [], moneda: [], tipoCobro: [], gestiones: [],

      items: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,


      cliente : {
        id: 0,
        numeroDocumento: 0,
        complemento: '',
        codigoCliente: '',
        nombreRazonSocial: '',
        codigoTipoDocumentoIdentidad: 0,
        email: '',
        telefono: ''
      },
      TipoDocumentoIdentidad: [],
    }
  },
  computed: {
    montoTotal: function () {
      let sum = 0;
      sum += parseFloat('0'+(modalPdf.factura.monto_1??0))+parseFloat('0'+modalPdf.factura.monto_2)+
          parseFloat('0'+modalPdf.factura.monto_3)+parseFloat('0'+modalPdf.factura.monto_4)+
          parseFloat('0'+modalPdf.factura.monto_5)+parseFloat('0'+modalPdf.factura.monto_6);
      sum = Math.round(sum*100)/100
      return sum
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async buscarnit() {
      let self = this;
      await axios.get(this.$apiAdress + '/api/facturaenlinea/' + self.factura.factura_nit + '/buscarnitci?token=' + localStorage.getItem("api_token")
      ).then(function (response) {
        self.cliente = {
          id: 0,
          numeroDocumento: 0,
          complemento: '',
          codigoCliente: '',
          nombreRazonSocial: '',
          codigoTipoDocumentoIdentidad: 0,
          email: '',
          telefono: ''
        };
        try {
          if (response.data.status && response.data.nitci!==null && response.data.nitci.id > 0) {
            self.factura.factura_nombre = response.data.nitci.nombreRazonSocial;
            self.cliente = response.data.nitci
          } else {
            self.cliente.numeroDocumento = self.factura.factura_nit
            if (response.data.nitci.length > 0) {
              self.cliente = response.data.nitci
            }
            self.modalCliente=true
          }
        } catch (e) {
          self.cliente.numeroDocumento = self.factura.factura_nit
          self.modalCliente=true
        }
      }).catch(function (error) {
        modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        self.modalCliente=true
      });
    },
    confirmarGrabar() {
      if (!modalPdf.$refs.formCreate.checkValidity()) {
        modalPdf.$refs.formCreate.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('Error', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.confirm.confirm('¿Esta segur@ de grabar la factura?', 'Después de este paso no podrá realizar más cambios',
            modalPdf.store,function (){});
      }
    },
    store() {
      modalPdf.factura.codigo_alumno = modalPdf.inscripcion.codigo_alumno;
      modalPdf.factura.carrera_id = modalPdf.inscripcion.carrera_id;
      modalPdf.factura.sede_id = modalPdf.sedeSeleccionada;
      axios.post(this.$apiAdress + '/api/facturaenlineaexterna/' + modalPdf.sedeSeleccionada + '/store?token=' + localStorage.getItem("api_token"),
          modalPdf.factura
      )
          .then(function (response) {
            modalPdf.$refs.modalVerify.mostrar(localStorage.sedeSeleccionada, response.data.factura_id);
            modalPdf.inscripcion = {
              facultad_id: 0,
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              apellido1: '',
              apellido2: '',
              nombres: '',
              genero_id: 0,
              fecha_nacimiento: '',
              nacionalidad_id: 0,
              documento_identificacion: '',
              traspaso_id: 0,
              estado_civil_id: 0,
              trabaja_id: 0,
              direccion: '',
              telefono: '',
              email: '',
              casilla: '',
            };modalPdf.factura = {
              factura_nit: '',
              factura_nombre: '',
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              moneda_id: 0,
              tipo_cambio: 0,
              semestre: '',
              tipo_cobro_id: 0,
              sede_id: 0,
              deposito: '',
              concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1: 0, deposito_1: '',
              concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2: 0, deposito_2: '',
              concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3: 0, deposito_3: '',
              concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4: 0, deposito_4: '',
              concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5: 0, deposito_5: '',
              concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6: 0, deposito_6: '',
            };
            modalPdf.cliente = {
              id: 0,
              numeroDocumento: 0,
              complemento: '',
              codigoCliente: '',
              nombreRazonSocial: '',
              codigoTipoDocumentoIdentidad: 0,
              email: '',
              telefono: ''
            };
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Factura creada satisfactoriamente.', 'success');
            modalPdf.mostrarGrilla=true
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    buscaMonto(concepto_id) {
      modalPdf.conceptoPago.forEach(function (data, index) {
          if (data.value == concepto_id) {
            return data.precio_unitario
          }
      });
      return 0;
    },
    actualizaMonto(item) {
      this.observacion = '';
      switch (item) {
        case 1:
          this.factura.monto_1 = this.buscaMonto(this.factura.concepto_id_1);
          this.factura.observacion_1 = this.observacion;
          break;
        case 2:
          this.factura.monto_2 = this.buscaMonto(this.factura.concepto_id_2);
          this.factura.observacion_2 = this.observacion;
          break;
        case 3:
          this.factura.monto_3 = this.buscaMonto(this.factura.concepto_id_3);
          this.factura.observacion_3 = this.observacion;
          break;
        case 4:
          this.factura.monto_4 = this.buscaMonto(this.factura.concepto_id_4);
          this.factura.observacion_4 = this.observacion;
          break;
        case 5:
          this.factura.monto_5 = this.buscaMonto(this.factura.concepto_id_5);
          this.factura.observacion_5 = this.observacion;
          break;
        case 6:
          this.factura.monto_6 = this.buscaMonto(this.factura.concepto_id_6);
          this.factura.observacion_6 = this.observacion;
          break;
      }
    },
    actualizarpagos(){
      this.factura.tipo_cobro_id_1=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_2=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_3=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_4=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_5=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_6=this.factura.tipo_cobro_id;
    },
    actualizarDepositos(){
      this.factura.deposito_1=this.factura.deposito;
      this.factura.deposito_2=this.factura.deposito;
      this.factura.deposito_3=this.factura.deposito;
      this.factura.deposito_4=this.factura.deposito;
      this.factura.deposito_5=this.factura.deposito;
      this.factura.deposito_6=this.factura.deposito;
    },
    reportePdf(factura_id) {
      modalPdf.$refs.alert.show('Descargando documento');
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + factura_id + '/facturapdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    limpiar(fila) {
      switch (fila) {
        case 0:
          this.factura.concepto_id = "";
          this.factura.monto = "";
          this.factura.observacion = "";
          break;
        case 2:
          this.factura.concepto_id_2 = "";
          this.factura.monto_2 = "";
          this.factura.observacion_2 = "";
          break;
        case 3:
          this.factura.concepto_id_3 = "";
          this.factura.monto_3 = "";
          this.factura.observacion_3 = "";
          break;
        case 4:
          this.factura.concepto_id_4 = "";
          this.factura.monto_4 = "";
          this.factura.observacion_4 = "";
          break;
        case 5:
          this.factura.concepto_id_5 = "";
          this.factura.monto_5 = "";
          this.factura.observacion_5 = "";
          break;
        case 6:
          this.factura.concepto_id_6 = "";
          this.factura.monto_6 = "";
          this.factura.observacion_6 = "";
          break;
      }
    },
    opcionesfactura(){
      axios.get(this.$apiAdress + '/api/facturaenlineaexterna/' + this.sedeSeleccionada + '/0/opcionesfactura?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.moneda = response.data.moneda;
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.conceptoPago = valorPorDefecto.concat(response.data.conceptoPago);
            modalPdf.tipoCobro = response.data.tipoCobro;
            for (let tip in modalPdf.tipoCobro) {
              if (modalPdf.tipoCobro[tip].label == "EFECTIVO") {
                modalPdf.tipoEfectivo = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_1 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_2 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_3 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_4 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_5 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_6 = modalPdf.tipoCobro[tip].value
              }
            }
            modalPdf.gestiones = response.data.gestiones;
            modalPdf.factura.moneda_id = response.data.moneda[0].value;
            modalPdf.factura.concepto_id_1 = response.data.conceptoPago[1].value;
            modalPdf.factura.tipo_cobro_id = response.data.tipoCobro[0].value;
            modalPdf.factura.gestion = response.data.mesActual;
            modalPdf.TipoDocumentoIdentidad = response.data.TipoDocumentoIdentidad;
          })
          .catch(function (error) {
            console.log(error)
            modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
          });
    },
    tieneColor(suboption){
      return (suboption.hasOwnProperty('color')?'text-'+suboption.color:'')
    },
    GrabarCliente() {
      if (!this.$refs.frmNuevoCliente.checkValidity()) {
        this.$refs.frmNuevoCliente.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('', 'Algunos datos son requeridos.', 'danger');
      } else {
        let self = this
        this.modalCliente=false
        modalPdf.$refs.alert.show("Grabando datos");
        this.$refs.confirm.confirm('¿Esta segur@ de grabar el nuevo cliente?', '', function () {
          axios.post(this.$apiAdress + '/api/facturaenlinea/' + self.sedeSeleccionada + '/grabarcliente?token=' + localStorage.getItem("api_token"), self.cliente
          ).then(function (response) {
            modalPdf.$refs.alert.hide();
            if (response.data.cliente.id > 0) {
              self.factura.factura_nit = response.data.cliente.numeroDocumento;
              self.factura.factura_nombre = response.data.cliente.nombreRazonSocial;
              modalPdf.$refs.mensajeToast.makeToast('', 'Cliente Registrado satisfactoriamente', 'success')
            }
          }).catch(function (error) {
            this.modalCliente=true
            modalPdf.$refs.mensajeToast.makeToast('', error.response.data.message, 'danger');
          });
        }, this.cancelarGrabarCliente);
      }
    },
    cancelarGrabarCliente() {
      this.modalCliente=false
      this.factura.factura_nit = ''
      this.cliente = {}
    },
    showToast(titulo,mensaje,color){
      this.$refs.mensajeToast.makeToast(titulo,mensaje,color)
    },
    mostrarDocumento(data){
      this.$refs.visorPDF.mostrarDocumento(data)
    }
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.opcionesfactura();
    // axios.post(this.$apiAdress + '/api/facturaexterna/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
    //     {'tipo_lista':'inscripcion_lista'}
    // )
    //     .then(function (response) {
    //       modalPdf.moneda = response.data.moneda;
    //       let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
    //       modalPdf.conceptoPago = valorPorDefecto.concat(response.data.conceptoPago);
    //       modalPdf.tipoCobro = response.data.tipoCobro;
    //       for (let tip in modalPdf.tipoCobro) {
    //         if (modalPdf.tipoCobro[tip].label == "Efectivo") {
    //           modalPdf.tipoEfectivo = modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_1= modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_2= modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_3= modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_4= modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_5= modalPdf.tipoCobro[tip].value
    //           modalPdf.factura.tipo_cobro_id_6= modalPdf.tipoCobro[tip].value
    //         }
    //       }
    //       modalPdf.gestiones = response.data.gestiones;
    //       modalPdf.factura.moneda_id = response.data.moneda[0].value;
    //       modalPdf.factura.concepto_id = response.data.conceptoPago[0].value;
    //       modalPdf.factura.tipo_cobro_id = response.data.tipoCobro[0].value;
    //       modalPdf.factura.gestion = response.data.mesActual;
    //     })
    //     .catch(function (error) {
    //       modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
    //     });
  }
}
</script>